.profile-card{
    .avt-title{
        @extend .font-weight-medium;
        color:#4F4F4F;
    }
    h5{
        color:#585A59;
    }
    .profile-desc{
        .ant-descriptions-item-label{
            @extend .font-weight-semibold;
            font-size:0.875rem;
            color:#4F4F4F;
            &:after{
                margin-right:50px;
            }
        }
    }
    .secret-password{
        position: relative;
        &:after{
            content:"***********";
            position: absolute;
            color: #4F4F4F;
            left:0;
            bottom:-30px;
        }
    }
    .ant-descriptions-item-content{
        color:#4f4f4f;
        font-weight: 400;
    }
}

.edit-pro-form{
    .ant-form-item{
       label{
           @extend .font-weight-semibold;
           color: #4F4F4F;
           font-size: 0.875rem;
           &:before{
               display:none!important;
           }
       } 
    }
}