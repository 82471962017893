.legendItem {
    display: inline-block;
    margin-right: 20px;
}

.legendItemColor {
    margin-right: 10px;
    display: inline-block;
    height: 15px;
    width: 15px;
}

.green {
    background-color: green;
}

.yellow {
    background-color: yellow;
}

.red {
    background-color: red;
}