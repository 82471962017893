.main-header{
    padding: 0 16px;
    @media screen and (max-width:767px){
        padding:0 15px;
        .ant-layout-sider-logo{
            img{
                width: 80%;
            }
        }
    }
}

.side-nav{
    .ant-menu{
        .ant-menu-item-selected{
            background-color:#F0F1F5;
            a{
                color: $primary;
            }
        }
        a, .ant-menu-title-content{
            @extend .font-weight-medium;
            color:rgba(0, 0, 0, 0.85);
            font-size:1rem;
        }
    }
    @media screen and (max-width:768px) {
        position: absolute;
        height: 100%;
    .ant-layout-sider-zero-width-trigger-left{
        position: fixed;
        top: 8px;
        left: 6px;
        bottom:auto;
        background-color: transparent;
        .anticon-bars{
            display: none;
        }
     }
   }
}

.collapsed-icon{
    position: absolute;
    bottom:0;
    left:0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left:15px;
    border-top:solid 1px #F0F0F0;
}

.cercadian-content-area{
    height: calc(100% - 64px );
}
