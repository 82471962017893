.login-container {
  @extend .w-100;
  max-width: 400px;
  padding: 3rem 0;
}

.lc-logo {
  @extend .text-center;
  margin-bottom: 1.875rem;
  img {
    max-width: 230px;
    @media screen and (max-width:767px){
      width:70%;
      margin:auto;
    }
  }
}

.login-card {
  margin-left: 1rem;
  margin-right: 1rem;

  h4 {
    @extend .text-center, .font-weight-semibold;
    margin-bottom: 15px;
    color:$primary;
    font-size:1.5rem;
  }
  .forgot-password{
    color: $primary;
  }
  .login-btn{
    background-color:$primary;
  }
  .ant-checkbox-inner{
    background-color: #004983;
    border-color: #004983;
  }
  .ant-input-prefix{
    margin-right: 17px;
  }
}

.login-page-marketing-container {
  width: 70%;
  padding: 3rem 1rem;
  color: #000000;
  position: relative;

  .content-background {
    background-image: url(../images/truck-sunset.png);
    background-repeat: no-repeat;
    opacity: 0.6;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: relative;
    display: block;
    position: absolute;
    background-position: right;
  }
  
  & > *{
    z-index: 100;
  }

  .login-page-marketing-content {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
    opacity: 1.0;
    background-color: transparent;
    position: relative;
  }

  h3 {
    font-size: 40px;
    color: #000000;
  }
  p.content {
    margin-bottom: 2rem;
  }

  .center {
    text-align: center;
  }

  ul {
    text-align: left;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  .sub-heading {
    font-size: 20px;
  }

  .button-links-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: stretch;
    align-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
  }

  .button-links-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: normal;
    align-items: center;
    align-content: center;
    gap: 3rem;
    width: auto;
  }

  .button-link {
    text-decoration: none;
    border: 2px solid #004983;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #000000;
  }

  .register-link {
    background-color: #004983;
    border-radius: 3px;
    color: #ffffff;
    padding: 5px 25px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .button-link.square {
    height: 8rem;
    width: 8rem;
  }

  .button-link.wide {
    width: 100%;
  }

  .quote-container {
    width: 75%;
    margin-right: auto;
    margin-left: auto;
  }

  .login-page-quote {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
  }

  .login-page-quote-name {
    font-size: 14px;
    text-align: right !important;
  }
}
