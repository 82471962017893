.search-card, .results-card {
  @media screen and (max-width: 991px) {
    margin-bottom: 16px;
  }

  h4 {
    font-size: 1.5rem;
  }

  .ant-card-head {
    border-bottom: none;
    .ant-card-head-title {
      font-size: 20px;
    }
  }
  .ant-card-body {
    padding-top: 0;
  }
  textarea {
    height: calc(100vh - 234px);
    min-height: 100px;
    // margin-bottom: 8px;
    resize: none;
    @media screen and (max-width: 991px) {
      height: calc(100vh - 450px);
    }

    &.error {
      border: 1px solid #f00;
      height: calc(100vh - 256px); // !important;
    }
    &.empty {
      height: calc(100vh - 256px); // !important;
    }
  }

  button.search-btn {
    width: 100%;
    margin-top: 8px;
  }

  .instructions-aside {
    background-color: #e5e5e5;
    padding: 15px 15px 5px 15px;
    width: 100%;

    h5 {
      font-size: 1.2rem;
      text-align: center;
    }

    .applications-list {
      display: inline-block;
      width: 50%;
      vertical-align: top;
      
      ul {
        margin-left: -20px;
      }
    }
  }
}

.search-card {
  margin-bottom: 16px;
}

.text-card {
  background: transparent;
  border: 0;
  
  @media screen and (max-width: 991px) {
    margin-bottom: 16px;
  }  
}
