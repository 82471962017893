
.leftLabel, .rightLabel {
    position: relative;
    top: 0;
    bottom: 0;
    margin: auto;
}

.xAxisLabel {
   text-align: center;
}

.leftLabel, .rightLabel, .xAxisLabel {
   color: #777;
   font-size: 16px;
   font-weight: 500;
}

.leftLabel {
    left: 0;
    top: 245px;
  -webkit-transform-origin: 0 50%;
     -moz-transform-origin: 0 50%;
      -ms-transform-origin: 0 50%;
       -o-transform-origin: 0 50%;
          transform-origin: 0 50%;
  -webkit-transform: rotate(-90deg);
     -moz-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
       -o-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
/* 
Kind of sloppy, but I had to get the positioning just right to fit 
around the graph
*/
.rightLabel {
   right: -35px;
   width: 120px;
   top: 220px;
   transform: rotate(90deg);
   float: right;
   clear: none;
}
