body {
  font-family: "Cabin", sans-serif;
}

img {
  max-width: 100%;
}

.heading-2 {
  font-size: 0.875rem !important;
  color: #4f4f4f !important;
}

.text-primary {
  color: $text-primary;
}

.font-weight {
  &-normal {
    font-weight: 400;
  }

  &-medium {
    font-weight: 500;
  }

  &-semibold {
    font-weight: 600;
  }

  &-bold {
    font-weight: 700;
  }
}

.card-box {
  overflow-y: auto;
  height: calc(100vh - 160px);
  &.search-card {
    height: calc(100vh - 100px);
    min-height: 100%;
  }
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #cacaca;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.ant-layout {
  min-height: 100vh;

  .ant-layout-sider {
    @extend .bg-white;
    padding-top: 13px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    z-index: 20;

    &-logo {
      max-width: 154px;
      width: 154px;
    }
  }

  .ant-page-header {
    padding: 12px 22px;
    &-content {
      padding-top: 0;
    }
  }

  &.guest-layout {
    padding-left: 15px;
    padding-right: 15px;
    background-image: url(../images/login-bg.png);
    background-position: center center;
    background-repeat: no-repeat;
    .ant-layout-content {
      @extend .d-flex, .align-items-center, .position-relative;
      padding-bottom: 50px;
      @media screen and (max-width: 76px) {
        padding-bottom: 40px;
        &-footer {
          padding: 10px;
        }
      }
    }
  }
  &-content {
    position: relative;
  }
  &-footer {
    position: absolute;
    bottom: 0;
    color: rgba(0, 0, 0, 0.45);
    left: 0;
    width: 100%;
    text-align: center;
  }
}

.ant-layout-sider-logo {
  image {
    width: auto;
  }
}

.cercadian-main-layout {
  box-shadow: 0 -1px 0px #f0f0f0;
  min-height: calc(100vh - 64px);
}

.ant-spin {
  @extend .position-absolute, .w-100, .h-100;
  left: 0%;
  top: 0%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 999;

  .ant-spin-dot-spin {
    @extend .position-absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
  }
}

.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 11px;
}

.app-header-right {
  float: right;

  .loggedin-user-dd {
    .ant-dropdown-trigger {
      display: block;
    }
    & > a {
      @extend .font-weight-medium;
      color: #262626;

      .ant-avatar,
      .anticon {
        margin: 0 5px;
      }

      .anticon {
        &-down {
          color: #8c8c8c;
        }
      }
    }
  }
}

.cercadian-table {
  th {
    @extend .font-weight-medium;
    background-color: transparent !important;
    color: #262626;
    font-size: 1rem;
  }
  td {
    @extend .font-weight-medium;
    color: #595959;
    border-color: #e8e8e8;
    font-size: 0.875rem;
  }
  .ant-btn-link {
    padding: 0;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
  }
  @media screen and (max-width: 767px) {
    overflow: auto;
    table {
      width: 600px;
    }
  }
}

.cr-modal {
  .ant-descriptions-title {
    font-size: 0.875rem;
  }
  .ant-list-header {
    font-weight: 500;
    padding-top: 15px;
    padding-bottom: 15px;
    background: #fafafa;
    color: #262626;
  }
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-body {
    padding-top: 10px;
  }
  .user-list {
    margin-top: 30px;
    margin-bottom: 10px;
    .ant-list-item {
      height: 60px;
      font-weight: 500;
      color: #595959;
      &:hover {
        background-color: #f8f8f8;
      }
    }
  }
  .ant-descriptions-header {
    margin-bottom: 10px;
  }
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    font-weight: 500;
    color: #474747;
  }
  .ant-select-multiple {
    .ant-select-selection-item {
      background: #f0f1f5;
      border: 1px solid #e8e8e8;
    }
  }
  .ant-modal-footer {
    button {
      height: 40px;
      color: #8f939e;
      &.ant-btn-primary {
        color: #fff;
        margin-left: 15px !important;
      }
    }
  }
  .ant-transfer {
    @media screen and (max-width: 767px) {
      display: block;
      .ant-transfer-operation {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
    .ant-transfer-list {
      width: 250px;
      border: 1px solid #e8e8e8;
      .ant-transfer-list-header {
        height: 54px;
        background-color: #fafafa;
        color: #262626;
        font-weight: 500;
        .ant-transfer-list-header-title {
          text-align: left;
        }
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .ant-transfer-operation {
      button {
        background: none;
        border-color: transparent;
        box-shadow: none;
        margin: 0 28px;
        &:after {
          display: none;
        }
        .anticon {
          display: none;
        }
      }
    }
    .ant-transfer-list-header-selected,
    .ant-transfer-list-header-dropdown,
    .ant-transfer-list-checkbox {
      display: none;
    }
    .ant-transfer-list-content {
      li {
        min-height: 54px;
      }
      .ant-transfer-list-content-item-checked {
        background: $primary;
        color: #fff;
      }
      li + li {
        border-top: solid 1px #e8e8e8;
      }
    }
  }
  .ant-modal-footer {
    padding: 24px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 15px;
  }
}

.user-mail {
  .ant-descriptions-item-content {
    font-weight: 400;
    color: #4f4f4f;
  }
}

.ant-menu-item-selected {
  height: 40px;
  line-height: 30px;
  background-color: #f0f1f5 !important;
}

.responsive-collapse-icon {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 767px) {
  .responsive-collapse-icon {
    display: none;
  }
}
.FatigueScore-0-30 {
  background: #abf8ab;
}
.FatigueScore-30-60 {
  background: #f3f3a7;
}
.FatigueScore-Above-60 {
  background: #f8afaf;
}

.invalid-xml {
  color: red;
}

.cercadian-table {
  .ant-pagination {
    li {
      button {
        margin-top: 0;
      }
    }
  }
}
